import React from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Field from 'utils/forms/Field';

/**
 * Generic Form Builder
 * @param fields
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */

const Form = ({ schema, className }) => {
  const { fields, validation } = schema;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(validation),
  });
  const onSubmit = (data) => schema.onSubmit(data);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      {fields &&
        fields.map((field) => (
          <Field key={field.name} field={field} register={register} errors={errors} watch={watch} />
        ))}
    </form>
  );
};

export default Form;
