export const getMediaUrl = function (url, size = null) {
    let ret = process.env.REACT_APP_ASSET_URL + url
    if (size) {
        const retUrl = url
            ? url.formats
                ? url.formats[size]
                    ? url.formats[size].url
                    : null
                : null
            : null
        ret = retUrl
            ? process.env.ASSET_URL + retUrl
            : process.env.ASSET_URL +
            '/uploads/small_default_thumbnail_558bb991b9.jpg'
    }
    return ret
}
