import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import axios from "utils/axios";
import Form from "utils/forms/Form";
import {Client} from "models/client";

export default function AddEditClient() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [submitting, setSubmitting] = useState(false);
    const [response, setResponse] = useState({});

    async function onSubmit(data) {
        setSubmitting(true)
        const response = await axios.post('/clients', data);
        setResponse(response.data)
        setSubmitting(false)
    }

    const schema = Client;
    schema.onSubmit =  function (data){
        console.log(data)
    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add Client
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <h2 className={'my-0'}>Add client</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Form schema={schema}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Add Client
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
