import React from 'react';
import {Button, Input, Select, TextField} from '@material-ui/core';

/**
 * Generic form field
 * @param field
 * @param register
 * @param errors
 * @param watch
 * @returns {JSX.Element}
 * @constructor
 */
const Field = ({ field, register, errors, watch }) => {
  let GeneralField;
  const props = field.props;
  field.holderClassName = field.holderClassName || 'w-100 my-2'
  props.className = props.className || 'w-100 my-2'
  switch (field.selector) {
    case 'textarea': {
      GeneralField = (
        <textarea defaultValue={field.defaultValue} {...register(props.name)} {...props} />
      );
      break;
    }
    case 'date': {
      GeneralField = (
          <TextField
              defaultValue={field.defaultValue}
              {...register(props.name)}
              {...props}
              key={field.name}
              InputLabelProps={{
                shrink: true,
              }}
          />
      );
      break;
    }
    case 'select': {
      GeneralField = (
        <>
          <Select {...register(props.name)} {...props}>
            {field.options.map((option) => {
              return (
                <option value={option.value} key={option.name}>
                  {option.placeholder}
                </option>
              );
            })}
          </Select>
        </>
      );
      break;
    }
    case 'button': {
      GeneralField = (
        <Button
          defaultValue={field.defaultValue}
          {...register(props.name)}
          {...props}
          key={field.name}
        >
          {field.props.placeholder}
        </Button>
      );
      break;
    }
    default: {
      GeneralField = (
        <TextField
          defaultValue={field.defaultValue}
          {...register(props.name)}
          {...props}
          key={field.name}
        />
      );
      break;
    }
  }

  return (
    <div className={field?.holderClassName}>
      {GeneralField}
      {errors[props.name] && <p className={field.errorClassName}>{errors[props.name].message}</p>}
    </div>
  );
};

export default Field;
