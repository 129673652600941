import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

//-----------------------|| LOGO SV  G ||-----------------------//

import logo from 'assets/images/logo.png'

const Logo = () => {
    return (
        <img src={logo} width={165} alt={'Logo'}/>
    );
};

export default Logo;
