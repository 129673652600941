import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';


import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import ProjectDashboard from "views/dashboard/Project/ProjectDashboard";
import ClientDashboard from "views/dashboard/Client/ClientDashboard";
const AppUserAccountProfile = Loadable(lazy(() => import('views/application/users/account-profile/Profile')));

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/clients',
                '/dashboard/projects',
                '/user/account-profile'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/clients" component={ClientDashboard} />
                        <Route path="/dashboard/projects" component={ProjectDashboard} />
                        <Route path="/user/account-profile" component={AppUserAccountProfile} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
