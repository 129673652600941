import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';


/**
 * Account reducer
 * @param state
 * @param action
 * @returns {(*&{isInitialized: boolean, isLoggedIn, user})|(*&{isLoggedIn: boolean, user: null})|*|(*&{isLoggedIn: boolean, user})}
 */
const accountReducer = (state, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
