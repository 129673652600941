import {useEffect, useState} from "react";
import axios from "utils/axios";

/**
 * Global get hook
 * @param path
 * @param params
 * @returns {{data: {}, loading: boolean}}
 */
export function useGetData({path, params}){
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});

    useEffect(()=>{
        (async () => {
            const response = await axios.get(path);
            setData(response.data)
            setLoading(false)
        })().catch(err => {
            setLoading(false)
        });

    },[])


    return { loading, data}
}
