// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard.projects',
            title: <FormattedMessage id="projects" />,
            type: 'item',
            url: '/dashboard/projects',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'dashboard.clients',
            title: <FormattedMessage id="clients" />,
            type: 'item',
            url: '/dashboard/clients',
            icon: icons['IconDeviceAnalytics'],
            breadcrumbs: false
        }
    ]
};
