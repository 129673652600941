import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import Routes from 'routes';
import theme from 'themes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import {JWTProvider} from "contexts/JWTContext";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {
    const customization = useSelector((state) => state.customization);
    document.title = 'Management'

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll>
                        <JWTProvider>
                            <Routes />
                            <Snackbar />
                        </JWTProvider>
                    </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
