import React, {useState} from 'react';
import {Box, Checkbox, FormControlLabel, InputLabel, MenuItem, Select} from "@material-ui/core";

const Filters = () => {
    const [sort, setSort] = useState("client");

    return (
        <Box width='100%' mb={2} display={'flex'} justifyContent='space-between' alignItems='center'>
            <Box>
                <InputLabel margin='0 5px'>Sort By</InputLabel>
                <Select
                    variant='outlined'
                    value={sort}
                    autoFocus
                    label="Sort By"
                    onChange={(e)=>setSort(e.target.value)}
                >
                    <MenuItem selected={true} value="client">Client</MenuItem>
                    <MenuItem value="type">Type</MenuItem>
                </Select>
            </Box>
            <div>
                <FormControlLabel control={<Checkbox checked name="pr" />}  label='PR'/>
                <FormControlLabel control={<Checkbox checked name="content" />}   label='Content'/>
                <FormControlLabel control={<Checkbox checked name="blogger_outreach" />}   label='Blogger Outreach'/>
                <FormControlLabel control={<Checkbox checked name="other" />}   label='Other'/>
            </div>
        </Box>
    );
};

export default Filters;