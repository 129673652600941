import React, {createContext, useEffect, useReducer} from 'react';
import jwtDecode from 'jwt-decode';
import {ACCOUNT_INITIALIZE, LOGIN, LOGOUT} from 'store/actions';
import accountReducer from 'store/accountReducer';
import axios from 'utils/axios';
import Loader from 'ui-component/Loader';

/**
 *
 * @type {{isInitialized: boolean, isLoggedIn: boolean, user: null}}
 */
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

/**
 *
 * @param jwt
 * @returns {boolean}
 */
const verifyToken = (jwt) => {
    if (!jwt) {
        return false;
    }
    const decoded = jwtDecode(jwt);
    return decoded.exp > Date.now() / 1000;
};

/**
 *
 * @param jwt
 */
const setSession = (jwt) => {
    if (jwt) {
        localStorage.setItem('jwt', jwt);
        axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    } else {
        localStorage.removeItem('jwt');
        delete axios.defaults.headers.common.Authorization;
    }
};


/**
 *
 * @type {React.Context<{logout: Auth0Client.logout, isInitialized: boolean, isLoggedIn: boolean, login: (function(): Promise<void>), user: null}>}
 */
const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {
    }
});


export const JWTProvider = ({children}) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const login = async (email, password) => {
        delete axios.defaults.headers.common.Authorization;
        let response = await axios.post('/auth/local', {identifier: email, password});
        const {jwt, user} = response.data;
        setSession(jwt);
        dispatch({
            type: LOGIN,
            payload: {
                user
            }
        });
    };

    const logout = () => {
        setSession(null);
        dispatch({type: LOGOUT});
    };

    useEffect(() => {
        const init = async () => {
            try {
                const jwt = window.localStorage.getItem('jwt');
                if (jwt && verifyToken(jwt)) {
                    setSession(jwt);
                    const response = await axios.get('/users/me');
                    const user = response.data;
                    const profile = await axios.get('/profiles/'+user.profile);
                    user.profile = profile.data
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader/>;
    }

    return <JWTContext.Provider value={{...state, login, logout}}>{children}</JWTContext.Provider>;
};

export default JWTContext;
