import React from 'react';
import {Box, Typography} from "@material-ui/core";

const Project = ({project}) => {
    return (
        <Box mt={2} borderTop={'1px solid lightgray'}>
            <Typography mt={1}>
                {project.name}
            </Typography>
        </Box>
    );
};

export default Project;