import React, {useState} from 'react';
import MainCard from "ui-component/cards/MainCard";

import Filters from "views/dashboard/Project/Header/Filters";
import {useGetData} from "hooks/useData";
import {Box, Typography} from "@material-ui/core";
import {getMediaUrl} from "utils/media";
import {Delete, Settings} from "@material-ui/icons";
import Project from "views/dashboard/Project/Components/Project";
import Permission from "../../../utils/permissions/Permission";
import AddEditProject from "./Components/AddEditProject";

const ProjectDashboard = () => {
    const {data, loading} = useGetData({path: '/clients'})

    return (
        <MainCard>
            <h2>
                Projects
            </h2>

            <Permission component={<AddEditProject/>} id={'add.project'}/>

            <Filters/>
                {!loading && data && Array.isArray(data) && data.map((client) => {
                    return <Box my={1} borderRadius={3} border='1px solid lightgray' p={2} width='100%'>
                        <Box width='100%' display={"flex"}  alignItems='center' justifyContent={'space-between'}>
                            <Box display={"flex"} alignItems={'center'}>
                                <img className={'me-2'} width={40} alt={`${client.name}_logo`} src={getMediaUrl(client.logo.url)}/>
                                {client.name}
                            </Box>
                            <div>
                                <Settings className='me-2'/>
                                <Delete/>
                            </div>
                        </Box>
                        <Typography className={'mt-3'}>
                            Projects
                        </Typography>
                        {client.projects.length ? client.projects.map(project=>{
                            return <Project project={project}/>
                        }) : <Typography>Client has no projects</Typography>}
                    </Box>})}
        </MainCard>
    );
};

export default ProjectDashboard;