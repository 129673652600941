import React, {useContext} from 'react';
import JWTContext from "contexts/JWTContext";
import {permissions} from "constants/permissions";

/**
 *
 * @param component
 * @param id
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const Permission = ({component, id, type='view'}) => {
    const {user} = useContext(JWTContext)
    const perms = permissions[user.role.type];
    return (
        <>
            {perms && perms[type]?.includes(id) && component}
        </>
    );
};

export default Permission;