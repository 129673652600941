import Joi from "joi";

export const Client = {
    validation: Joi.object({
        name: Joi.string().required(),
        description: Joi.string().required(),
        join_date:  Joi.string().required(),
        projects:  Joi.array().required(),
        contact_person: Joi.number().required(),
        submit: Joi.allow()
    }),
    fields: [
        {
            props: {placeholder: 'Name', variant: 'outlined', type: "text", name: 'name', required: true},
            selector: 'input'
        },
        {
            props: {placeholder: 'Description', variant: 'outlined', type: "text", name: 'description', required: true},
            selector: 'input'
        },
        {
            props: {placeholder: 'Join date', variant: 'outlined', type: "date", name: 'join_date', required: true},
            selector: 'input'
        },
        {
            props: {placeholder: 'contact_person', variant: 'outlined', type: "text", name: 'contact_person', required: true},
            selector: 'input'
        },
        {   props: {placeholder: 'Add client', type: "submit", name: 'submit',variant:'outlined' , disabled : false},
            selector: 'input'
        }
    ],
}