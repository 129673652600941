import Joi from "joi";

export const Project = {
    validation: Joi.object({
        name: Joi.string().required(),
        start_date: Joi.string().required(),
        end_date:  Joi.string().required(),
        monthly_budget:  Joi.number().required(),
        pr_budget: Joi.number().required(),
        content_budget: Joi.number().required(),
        blogger_outreach_budget: Joi.number().required(),
        manual_link_building_budget: Joi.number().required(),
        dev_budget: Joi.number().required(),
        other_budget: Joi.number().required(),
        submit: Joi.allow()
    }),
    fields: [
        {
            props: {label: 'Name', variant: 'outlined', type: "text", name: 'name', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Start date', variant: 'outlined', type: "date", name: 'start_date', required: true},
            selector: 'date'
        },
        {
            props: {label: 'End date', variant: 'outlined', type: "date", name: 'end_date', required: true},
            selector: 'date'
        },
        {
            props: {label: 'Monthly budget', variant: 'outlined', type: "number", name: 'monthly_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'PR Budget', variant: 'outlined', type: "number", name: 'pr_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Content budget', variant: 'outlined', type: "number", name: 'content_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Blogger outreach budget', variant: 'outlined', type: "number", name: 'blogger_outreach_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Manual link building budget', variant: 'outlined', type: "number", name: 'manual_link_building_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Dev budget', variant: 'outlined', type: "number", name: 'dev_budget', required: true},
            selector: 'input'
        },
        {
            props: {label: 'Other budget', variant: 'outlined', type: "number", name: 'other_budget', required: true},
            selector: 'input'
        },
        {   props: {placeholder: 'Add project', type: "submit", name: 'submit',variant:'outlined' , disabled : false},
            selector: 'input'
        }
    ],
}