import React, {useState} from 'react';
import MainCard from "ui-component/cards/MainCard";

import {Box} from "@material-ui/core";
import Permission from "utils/permissions/Permission";
import {useGetData} from "hooks/useData";
import {getMediaUrl} from "utils/media";
import {Delete, Settings} from "@material-ui/icons";
import AddEditClient from "views/dashboard/Client/Component/AddEditClient";
import axios from "utils/axios";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ClientDashboard = () => {

    const {data, loading} = useGetData({path: '/clients'})

return (
        <MainCard>
            <h2>
                Clients
            </h2>

            <Permission component={<AddEditClient/>} id={'add.client'}/>
            {!loading && data && Array.isArray(data) && data.map((client) => {
                    return <Box my={1} borderRadius={5} border='1px solid lightgray' p={2} display='flex' alignItems='center' justifyContent={'space-between'}>
                        <Box display={"flex"} alignItems={'center'}>
                            <img className={'me-2'} width={40} alt={`${client.name}_logo`} src={getMediaUrl(client.logo.url)}/>
                            {client.name}
                        </Box>
                       <div>
                           <Settings className='me-2'/>
                           <Delete/>
                       </div>
                    </Box>})}
        </MainCard>
    );
};

export default ClientDashboard;