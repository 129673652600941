/**
 *
 * @type {{general_admin: {view: string[], edit: *[], delete: *[]}, client: {view: string[], edit: *[], delete: *[]}}}
 */
export const permissions = {
        client: {
                view: ['dashboard.projects','dashboard.clients'],
                edit: [],
                delete: [],
        },
        general_admin: {
                view: ['dashboard.projects','dashboard.clients','add.client', 'add.project'],
                edit: ['projects'],
                delete: [],
        },

    }